import styled from 'styled-components';
import { device } from '@retina-packages/retina-theme-bootstrap/packages/css/device';

export const GenTestBenefitWrapperMain = styled.div`
  .generic-testing-benefits{
    &:before{
      @media ${device.ipadLandscapemin} and ${device.desktopmenu}{
        top: -68px;
      }

    }
  }

  .community-resources {
    .video-container {
      .o-inner {
        .video-thumbnail-section-main {
          .video-thumbnail-right {
            .o-header {
              p {
                strong {
                  font-weight: 600;
                }
              }
            }

            .o-paragraph {
              p {
                font-size: 1.8rem;
                line-height: 2.6rem;
                font-family: "Gotham-Book", Arial, Helvetica, sans-serif;
              }
            }
          }
        }
      }
    }
  }
  .headerwraphead{
    .topnav{
      .healthcare{
        @media ${device.desktopmenu}{
          margin: 0;
          margin-right: 25px;
        }
      }
    }
  }

  .clinical-trials-banner{
    height: 100%;
    &:after {
      @media ${device.laptopMedium}{
        top: 9px;
      }
    }
    .internal-banner{
      position: relative;
      @media ${device.laptopMedium}{
        height: 500px;
      }
      .gatsby-image-wrapper-constrained{
        @media ${device.laptopL}{
          width: 100%;
        }
        @media ${device.laptopMedium}{
          width: 100%;
          height: 470px;
          object-position: 90% 0%;
          object-fit: cover;
        }
      }
      .internal-banner__image{
        .desktop-image{
          height: 100%;
            .media--image{
              height: 100%;
            }
        }
        .mobile-image{
          .media--image{
            height: 100%;
          }
          img{
            display: none;
            @media ${device.laptopPros}{
              display: block;
              object-fit: cover;
              width: 100%;
              height: 100%;
              object-position: center !important;
            }
          }
        }
      }
      .internal-banner__contant{
        .o-container{
          @media ${device.mobileMedium} {
            padding: 0;
          }
          h1{
              @media ${device.desktopStart}{
                padding: 0;
              }
            }
          }
        }
    }
  }
  .testing-benefits-listing{
    .o-container{
      .o-inner{
        .o-header--h2{
          @media ${device.ipadLandscapemin}{
           margin-bottom: 1rem;
            }
        }
        .more-answer-paragraphs{
          p{
            padding: 25px 0 24px 0;
            @media ${device.ipadLandscapemin}{
              padding: 40px 0;
              }
          }
        }
      }
    }
  }
 .genes-wrapper{
  @media ${device.desktopsignup}{
    padding: 0 15px;
  }
  .footer-block{
    .footer-block--row{
      .footer-inner-block{
        p{
          a{
            &: after{
              @media ${device.desktopmenu}{
                width: 16px;
                height: 14px;
              }
              @media ${device.desktopsignup} {
                height: 15px;
                width: 16px;
              }
            }
          }
        }
      }
    }
  }
  .o-container{
    .o-inner-wrapper-gens{
      .o-genes-wrap {
        .info-box-2-icon{
          @media ${device.laptopMedium}{
            width: 44px;
            text-align: center;
            display: block;
            margin: auto;
          }
          @media ${device.ipadLandscapemin} and  ${device.desktopmenu}{
            width: 188px;
            height: 72.4px;
          }
          @media ${device.desktopsignup}{
            width: 150px;
            height: 50.4px;
          }
          .media--image{
            .gatsby-image-wrapper{
              width: 44px;
              height: 40px;
              top:0;
              @media ${device.ipadLandscapemin}{
                width: 80px;
                height: 72px;
              }
              @media ${device.desktopsignup}{
                top: -10px;
              }

              img{
                object-fit: cover;
                opacity: 1;
                left: unset;
                width: 100%;
                height: 100%;
                bottom: 0;
                left: 0;
                margin: 0;
                max-width: none;
                padding: 0;
                position: absolute;
                right: 0;
                top: 0;
              }
            }
          }
        }
      }
    }
  }
 }
  .generic-testing-benefits {
    .early-testing-benefita-container {
      .testing-list-bg {
        .o-container {
          .bg-color-component {
            .listing-box {
              .o-top-space {
                .genetic-points-paragraphs {
                  ul {
                    li {
                      @media ${device.laptopMedium} {
                        padding: 6px 0 6px 24px;
                        font-size: 1.8rem;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .o-inner{
      .genetic-cause{
        .genetic-cause__right-text-secion{
          p{
            font-family: "Gotham", Arial, Helvetica, sans-serif;
            font-size: 2rem;
            line-height: 2.4rem;
            @media ${device.ipadLandscapemin}{
              font-size: 2.7rem;
              line-height: 3.8rem;
            }
          }
        }
      }
    }
    .genetic-left-img-points {
      .left-image-points {
        .listing-btm-section {
          ul {
            &.right-description {
              li {
                &:nth-child(2) {
                  margin-left: 25px;

                  &:before {
                    border-radius: 50%;
                  }
                }

                &:before {
                  @media ${device.tablet} and ${device.laptopMedium} {
                    top: 11px;
                  }

                  @media ${device.laptopPros} {
                    top: 12px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .o-container {
    padding: 0 2rem;

    @media ${device.laptopPros} {
      padding: 0 2.7rem;
    }

    @media ${device.ipadLandscape} {
      max-width: 718px;
    }
  }

  .o-backtotop-btn {
    @media ${device.ipadLandscape} {
      top: -100px;
    }
    @media ${device.ipadLandscape}{
      background: #300064;
    }
  }

  .logo-section {
    .o-inner {
      .o-text-wrap {
        &:nth-child(2) {
          p {
            @media ${device.laptopPros} {
              padding: 0;
            }
          }
        }

        &:nth-child(4) {
          p {
            @media ${device.laptopMedium} {
              font-size: 2rem;
              line-height: 2.4rem;
              padding-bottom: 0;
              margin: 0;
            }

            strong {
              @media ${device.laptopMedium} {
                font-size: 2rem;
                line-height: 2.4rem;
                padding-bottom: 0;
              }
            }
          }
        }
      }

      .logo-block {
        ul {
          @media ${device.laptopMedium} {
            width: 100% !important;
          }

          li {
            @media ${device.mobileMedium} {
              height: 100px;
              line-height: 100px;
            }
          }
        }
      }

      .btm-logo-block {
        @media ${device.laptopMedium} {
          padding-bottom: 80px;
        }
      }

      .btm-logo-title {
        @media ${device.laptopMedium} {
          padding: 48px 0 24px;
          width: 70%;
          margin: auto;
          font-size: 2rem;
          line-height: 2.4rem;
        }
      }
    }
  }

  .title-card {
    background-color: #300064;
    margin:  0 0 120px 0;
    padding: 87px 100px;

    @media ${device.ipadLandscape} {
      margin: 40px 0;
      padding: 44px 43px 48px;
    }

    .o-inner {
      padding: 0;

      h2 {
        color: #fff;
        text-align: center;
        padding-bottom: 4rem;
        font-size: 4.2rem;
        font-weight: 600;
        line-height: 5rem;

        @media ${device.ipadLandscape} {
          padding-bottom: 19px;
          font-size: 2.5rem;
          line-height: 3rem;
        }
      }

      .footer-block {
        padding: 0;
        margin: 0;
        display: flex;
        width: 100%;

        @media ${device.ipadLandscape} {
          display: block;
        }

        .footer-inner-block {
          text-align: center;
          position: relative;
          border-radius: 5px;
          background-color: #fff;
          box-shadow: 0px 2px 4px rgb(17 17 17 / 16%);
          padding: 38px 24px 120px;
          margin: 0 16px;
          width: calc(50% - 26px);

          @media ${device.ipadLandscapemin} and ${device.midDesktopMax} {
            padding: 38px 24px 133px;
          }

          @media ${device.ipadLandscape} {
            border-radius: 10px;
            padding-top: 28px;
            padding-bottom: 16px;
            width: 100%;
            padding-left: 16px;
            padding-right: 16px;
            margin-top: 20px;
            width: 100%;
          }

          &:before {
            content: "";
            background: linear-gradient(90deg, #8000be 0%, #8000be 45%, #594dc6 100%);
            width: 100%;
            height: 16px;
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 5px 5px 0 0;

            @media ${device.ipadLandscape} {
              border-radius: 10px 10px 0 0;
            }
          }

          &:first-child {
            margin-left: 0;
          }

          &:nth-child(2) {
            &:before {
              background: linear-gradient(90deg, #3398ce 0%, #3398ce 25%, #17d0d4 74%, #06f2d7 100%);
            }

            @media ${device.ipadLandscape} {
              margin-left: 0;
              margin-top: 15px;
            }
          }

          p,.title-card-heading,.o-common-title {
            max-width: 400px;
            font-size: 2.7rem;
            line-height: 3.8rem;
            color: #8000BE;
            text-align: center;
            font-weight: 700;
            margin: 0;

            @media ${device.ipadLandscape} {
              font-size: 2rem;
              line-height: 2.4rem;
              padding-bottom: 15px;
            }
          }

          a {
            left: 23px;
            right: 23px;
            bottom: 30px;
            padding: 12px 23px 12px 23px;
            display: table;
            align-items: center;
            box-shadow: 0px 3px 4px #d6d6d6;
            position: absolute;
            width: 90%;
            text-align: center;
            margin: 48px auto 0;
            background: #8000BE;
            color: #fff;
            border: 1px solid #fff;
            font-size: 2.2rem;
            font-weight: 500;
            line-height: 2.4rem;
            text-decoration: none;
            z-index: 1;
            vertical-align: top;
            overflow: hidden;
            letter-spacing: 0;
            border-radius: 6px;
            cursor: pointer;

            @media ${device.ipadLandscape} {
              display: block;
              position: initial;
              font-size: 1.8rem;
              line-height: 2.6rem;
              margin: 15px auto 0;
              width: 100%;
            }

            &:hover {
              background: #fff;
              color: #8000BE;
              border-color: #fff;
              font-weight: 700;
              box-shadow: 0px 3px 4px #d6d6d6;
            }

            &:after {
              content: '';
              display: inline-block;
              height: 20px;
              width: 16px;

              @media ${device.ipadLandscape} {
                display: block;
                margin: 0 auto;
              }
            }
          }
        }
      }
    }
  }
`;

export const EarlyTestingBenefit = styled.div`
	.testing-list-bg {
		position: relative;

		&:before {
			height: calc(100% - 210px);
			content: "";
			background-color: #00FFD9;
			position: absolute;
			top: 120px;
			left: 0;
			width: 100%;
		}

		.o-container {
			.bg-color-component {
				background-color: #F7F7F7;
				box-shadow: 0px 5px 15px 0px rgb(0 0 0 / 10%);
				position: relative;

				@media ${device.laptopMedium} {
					padding: 30px;
				}

				&:before {
					content: "";
					background: linear-gradient(90deg, #8000be 0%, #594dc6 35%, #17d0d4 64%, #00ffd9 100%);
					position: absolute;
					top: 0;
					width: 100%;
					height: 16px;
					left: 0;
					right: 0;

					@media ${device.laptopMedium} {
						height: 8px;
					}
				}

				.listing-box {
					margin-top: 30px;

					@media ${device.laptopMedium} {
						margin-top: 0;
					}

					.o-top-space {
						padding: 95px 100px 80px;

						@media ${device.laptopMedium} {
							padding: 5px 0 0;
						}

						h2 {
								margin: 0;
								font-size: 4.2rem;
								font-weight: 600;
								line-height: 5rem;
								color: #000000;
    						text-align: center;

								@media ${device.laptopMedium} {
									font-size: 2.5rem;
    							line-height: 3rem;
								}
						}

						.genetic-points-paragraphs {
							ul {
								padding: 0;
								margin: 16px auto 0;
								display: table;

								@media ${device.laptopMedium} {
									margin: 12px auto 0;
    							padding: 0 12px;
								}

								li {
									padding: 16px 0 8px 25px;
									display: inline-block;
    							position: relative;
									color: #000000;
									font-size: 2.2rem;
									font-weight: 500;
									line-height: 3.2rem;

									@media ${device.laptopMedium} {
										padding: 6px 0 6px 24px
										font-size: 1.8rem;
    								line-height: 2.2rem;
									}

									&:before {
										top: 32px;
										content: "";
										position: absolute;
										background-color: #8000BE;
										width: 8px;
										height: 8px;
										transform: translateY(-50%);
										margin: auto;
										left: 0;

										@media ${device.laptopMedium} {
											top: 18px;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;
