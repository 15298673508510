export const patientGTI: any = {
  mainMenu: "Main Navigational Menu Consumer GCSO",
  footerMenu: "Footer Navigation Menu Consumer GCSO",
  banner: "Banner - HTML - Genetic Benefits - DTC - Canada",
  footer: "Footer DTC HTML Canada",
  exitPopup: "Retina Canada Exit Popup HTML",
  hcpSwitcher: "HCP Switcher Modal Pop Up",
  topNav: "Top Navigation Menu Retina Canada HTML DTC",
  infographic: "GENETIC TESTING BENIFITS- INFOGRAPHIC",
  footerCallout: "Footer Callout GTB - HTML DTC Canada",
  siteLogo: "Site logos DTC",
  moreAnswersSection: "More answers HTML - GTB Consumer Canada",
  over270Genes: "Over 270 Genes HTML - GTB Consumer Canada",
  earlyTesting: "Early genetic testing HTML - GTB - Consumer canada",
  prepareToSpeak: "Prepare to speak with your eye specialist",
  GTInconclusive: "Genetic Testing Inconclusive",
  redirectLangUrl: {
    "redirectUrl": {
      "en": "/genetic-testing-importance",
      "fr": "/fr/genetic-testing-importance"
    }
  },
  backToTopGTM: "Back to top - Importance of Genetic testing",
  footerClassName: "genetic-testing-importance-footer"
}
